import React, { useState, useEffect, useRef } from "react"
import {
  ChakraProvider,
  Box,
  Link,
  Grid,
  extendTheme,
  Button,
  Flex,
  Text,
  Image,
  IconButton,
  Icon
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import logo from './logo.png'
import loopVideo from "./loop.mp4"
import bgDesktopLoading from "./bg.png"
import bgGif from "./bg.gif";
import divider from "./divider.png"
import { FaTwitter, FaDiscord, FaBook, FaBlogger } from 'react-icons/fa';

const theme = extendTheme({
  colors: {
    brand: {
      50: "#011c37",
      500: "#fe6f03", // you need this
      600: "#fe6f03", // you need this
    }
  }
});

export const App = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      {width > 800 ?

        (
          <Grid minH="100vh" style={{
            backgroundColor: "#000",
            backgroundImage: `url(${bgDesktopLoading})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}>
            <Box style={{ zIndex: 6, position: "absolute", top: 30, left: 0, right: 0, width: "100%", margin: "0 auto" }}>
              <Box style={{ maxWidth: "100%", width: "100%", display: "block", justifySelf: "right", }}>
                <Flex style={{ justifyContent: "right", marginRight: "30px" }} gap={10} className="audiowide">
                  <Link href="https://github.com/Hydrogen-Labs/anzen-contracts" style={{ textTransform: "uppercase", opacity: "0.6", zIndex: 99, color: "#fff", fontSize: "18px", cursor: "pointer" }}>Github</Link>
                  <Image src={divider} style={{ maxHeight: "80px", marginTop: "-30px" }} />
                  <Link href="https://github.com/Hydrogen-Labs/Anzen-Whitepaper-Release/blob/main/Anzen_Protocol_Whitepaper_V0_1_Alpha.pdf" style={{ textTransform: "uppercase", opacity: "0.6", zIndex: 99, color: "#fff", fontSize: "18px", cursor: "pointer" }}>WhitePaper</Link>
                </Flex>
              </Box>
            </Box>
            <Box style={{ zIndex: 5, }}>
              <Box style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Image src={logo} style={{ maxWidth: "180px" }} />
                <Text lineHeight={"60px"} className="audiowide" color={"#fff"} fontSize={"5rem"}>ANZEN</Text>
                <Text lineHeight={"60px"} className="audiowide" color={"#fff"} fontSize={"1rem"}>OPTIMIZING AVS OPERATOR PAYMENTS ON EIGENLAYER</Text>
                <Button style={{ color: "#fff", borderColor: "rgba(255, 255, 255, 0.16)" }} color="#fff" variant='outline' className="audiowide" onClick={() => window.open("https://woy60j8xjb4.typeform.com/to/P2kPY0Lt", '_blank')}>REGISTER YOUR AVS</Button>
              </Box>
            </Box>
            <Box style={{ zIndex: 6, position: "absolute", bottom: 30, left: 0, right: 0, width: "200px", margin: "0 auto" }}>
              <Box style={{ maxWidth: "100px", width: "200px", display: "block", justifySelf: "center", }}>
                <Flex style={{ justifyContent: "space-between" }} gap={10}>
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://discord.gg/54bKCbAA5E", '_blank')} icon={<Icon style={{ opacity: "0.6", zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://twitter.com/AnzenProtocol", '_blank')} icon={<Icon style={{ opacity: "0.6", zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaTwitter} />} />
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://anzen.substack.com/", '_blank')} icon={<Icon style={{ opacity: "0.6", zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                </Flex>
              </Box>
            </Box>
            <video
              poster={bgDesktopLoading}
              autoPlay={true}
              muted={true}
              loop={true}
              style={{ objectFit: "fill", minHeight: "100%", minWidth: "100%", width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0 }}
            >
              <source src={loopVideo} type="video/mp4" />
            </video>
          </Grid>

        ) : (
          <Grid minH="100vh" style={{
            backgroundColor: "#000",
            backgroundImage: `url(${bgDesktopLoading})`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }}>
            <Box className="mobileright audiowide" style={{ zIndex: 6, position: "absolute", top: 50, right: 20, margin: "0 auto" }}>
              <Link href="https://github.com/Hydrogen-Labs/anzen-contracts" mr="20px" style={{ textTransform: "uppercase", opacity: "1", zIndex: 99, color: "#fff", fontSize: "14px", cursor: "pointer" }}>Github</Link>
              <Link href="https://github.com/Hydrogen-Labs/Anzen-Whitepaper-Release/blob/main/Anzen_Protocol_Whitepaper_V0_1_Alpha.pdf" style={{ textTransform: "uppercase", opacity: "1", zIndex: 99, color: "#fff", fontSize: "14px", cursor: "pointer" }}>WhitePaper</Link>
            </Box>
            <Box style={{ zIndex: 5, }}>
              <Box style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                <Image src={logo} style={{ maxWidth: "120px" }} />
                <Text lineHeight={"60px"} className="audiowide" color={"#fff"} fontSize={"3rem"}>ANZEN</Text>
                <Text lineHeight={"60px"} className="audiowide" color={"#fff"} fontSize={"0.7rem"}>OPTIMIZING PAYMENTS ON EIGENLAYER</Text>
                <Button style={{ color: "#fff" }} color="#fff" variant='outline' className="audiowide" onClick={() => window.open("https://woy60j8xjb4.typeform.com/to/P2kPY0Lt", '_blank')}>REGISTER YOUR AVS</Button>
              </Box>
            </Box>
            <Box style={{ position: "absolute", bottom: 50, left: 0, right: 0, width: "200px", margin: "0 auto" }}>
              <Box style={{ maxWidth: "100px", width: "200px", display: "block", justifySelf: "center", }}>
                <Flex style={{ justifyContent: "space-between" }} gap={10}>
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://discord.gg/54bKCbAA5E", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://twitter.com/AnzenProtocol", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaTwitter} />} />
                  <IconButton aria-label="" variant="link" onClick={() => window.open("https://anzen.substack.com/", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                </Flex>
              </Box>
            </Box>
          </Grid>
        )
      }
    </ChakraProvider >
  )
}
